import React, { FunctionComponent } from 'react'
import { Link } from 'gatsby'

import styled from 'styled-components'
import MainGrid from '../../styling/MainGrid'
import Disclaimer from './Disclaimer'

import theme from '../../styling/theme'
import logo from '../../../images/logo.svg'
import badge1 from '../../../../static/img/footerlogos/rda-iso.jpg'

const data = [
  {
    title: 'RDA',
    links: [
      {
        title: 'R&D Tax Credit',
        url: '/tax-credit',
      },
      {
        title: 'Claims Process',
        url: '/claims-process',
      },
      {
        title: 'Industry Sector',
        url: '/industry-sector',
      },
      {
        title: 'Sponsorships',
        url: '/sponsorships',
      },
    ],
  },
  {
    title: ' ',
    links: [
      {
        title: 'About us',
        url: '/about-us',
      },
      {
        title: 'Blog',
        url: 'https://blog.rda.co.uk',
      },
      {
        title: 'Careers',
        url: '/careers',
        tag: true,
        tagTitle: "We're hiring",
      },
      {
        title: 'Contact',
        url: '/contact-us',
      },
    ],
  },
]

interface FooterProps {
  isVisible?: boolean
}
const Footer: FunctionComponent<FooterProps> = ({ isVisible }) => {
  return (
    <MainGrid>
      <Container isVisible={isVisible}>
        <Logo src={logo} alt="R&D logo" style={{ height: '80px', width: 'auto' }} />
        <RightCol>
          <SectionHeading className="legal-text">RDA</SectionHeading>
          <LinksSection>
            {data &&
              data.map((item, key) => {
                return (
                  <Section key={key} margin={key === 0}>
                    {item.links &&
                      item.links.map((link, key) => {
                        return link.tag ? (
                          <TagLinkContainer key={key}>
                            <StyledLink
                              to={
                                (process.env.BASE === '/'
                                  ? ''
                                  : process.env.BASE) + link.url
                              }
                              nomarginbottom="true"
                            >
                              {link.title}
                            </StyledLink>
                            <span>{link.tagTitle}</span>
                          </TagLinkContainer>
                        ) : (
                          <StyledLink
                            to={
                              (process.env.BASE === '/'
                                ? ''
                                : process.env.BASE) + link.url
                            }
                            key={key}
                          >
                            {link.title}
                          </StyledLink>
                        )
                      })}
                  </Section>
                )
              })}
              <BadgeContainer style={{marginLeft: "auto"}}>
              <img src={badge1}/>
              </BadgeContainer>
          </LinksSection>
        </RightCol>
      </Container>
      <MobileBadgeContainer>
        <img src={badge1}/>
      </MobileBadgeContainer>
      <Disclaimer />
    </MainGrid>
  )
}

export default Footer

const MobileBadgeContainer = styled.div`
   display: none;
  @media (max-width: 500px) {
    display: block;
  }
`

const BadgeContainer = styled.div`
  margin-left: "auto";
  min-width: 155px;
  @media (max-width: 500px) {
    display: none;
  }
`

const Container = styled.footer`
  display: flex;
  flex-direction: column;
  padding-top: 6rem;
  border-top: 1px solid ${theme.grey.keyline};
  position: relative;
  background: white;
  z-index: 2;
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`
const RightCol = styled.div`
  width: 100%;
  margin-top: 30px;
  @media (min-width: 1024px) {
    margin-top: 0px;
    width: 52%;
  }
  @media (min-width: 1199px) {
    width: 52%;
  }
`

const LinksSection = styled.section`
  display: flex;
  width: 100%;

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    margin-top: 40px;
  }

  @media screen and (min-width: 1199px) {
    flex-direction: row;
    & div:first-child {
      padding-right: 6rem;
    }
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: ${props => (props.margin ? '6rem' : '0')};

  @media (min-width: 768px) {
    padding-right: ${props => (props.margin ? '10rem' : '0')};
  }
  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    margin-bottom: ${props => (props.margin ? '20px' : '0px')};
  }
`

const SectionHeading = styled.p`
  font-weight: 700;
  color: ${theme.text.main};
  margin-bottom: 2rem;
`
const StyledLink = styled(props => <Link {...props} />)`
  &,
  &:link,
  &:visited {
    font-size: 13px;
    line-height: 23px;
    font-weight: 300;
    color: ${theme.text.second};
    margin-bottom: ${props => (props.nomarginbottom ? '0px' : '14px')};
    position: relative;
    opacity: 0.8;
    overflow: hidden;
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
  &:hover,
  &:active {
    color: ${theme.brand.blue};
  }
  &::before {
    position: absolute;
    content: ' ';
    height: 2px;
    width: 100%;
    bottom: -1px;
    left: 0;
    background: ${theme.brand.blue};
    margin: 0 auto;
    background: linear-gradient(
      270deg,
      ${theme.brand.blue} 5%,
      rgba(11, 142, 54, 0.8) 10%,
      rgba(11, 142, 54, 0.1) 95%,
      ${theme.brand.blue}
    );
    transform-origin: left;
    transform: translateX(-100%);
    backface-visibility: hidden;
  }
  &:hover::before,
  &:active::before,
  &:focus::before {
    transition: transform 0.6s cubic-bezier(0.67, 0, 0.33, 1);
    transform: translateX(100%);
  }
`
const TagLinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;

  & span {
    text-transform: uppercase;
    font-family: Michroma;
    font-size: 7px;
    letter-spacing: -0.45px;
    line-height: 12px;
    background: ${theme.text.main};
    color: ${theme.text.white};
    border-radius: 3px;
    padding: 2px 5.5px;
    margin-left: 13px;
    @media (min-width: 768px) {
      font-size: 9px;
    }
  }
`
const Logo = styled.img`
  width: 147px;
`
