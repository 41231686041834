import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import logo from '../../../images/logo.svg'
import headerImg from '../../../images/header-promo-img.jpg'
import headerImgLCC from '../../../images/lcc-members-badge.png'
import MainGrid from '../../styling/MainGrid'
import theme from '../../styling/theme'
import { formatLink } from '../../../util/functions'

const stickyNavigation = [
  {
    title: 'R&D Tax Credit',
    slug: '/tax-credit',
    // slug: '/dev-test/rd-advisors/tax-credit',
  },
  {
    title: 'Claims Process',
    slug: '/claims-process',
    // slug: '/dev-test/rd-advisors/claims-process',
  },
  {
    title: 'Industry Sector',
    slug: '/industry-sector',
    // slug: '/dev-test/rd-advisors/industry-sector',
  },
  {
    title: 'Sponsorships',
    slug: '/sponsorships',
    // slug: '/dev-test/rd-advisors/sponsorships',
  },
  {
    title: 'About us',
    slug: '/about-us',
    // slug: '/dev-test/rd-advisors/about-us',
  },
  {
    title: 'Blog',
    slug: 'https://blog.rda.co.uk',
  },
  {
    title: 'Contact',
    slug: '/contact-us',
    // slug: '/dev-test/rd-advisors/contact-us',
  },
]
interface NavigationProps {
  activeLink?: string
}
const Navigation: FunctionComponent<NavigationProps> = ({ activeLink }) => {
  return (
    <Container>
    <TopNav>
  <MainGrid noPaddingMobile noPaddingTablet>
    <TopNavInner>
      <div className="logo-container">
        <a href={process.env.BASE}>
          <figure className="image">
            <img src={logo} alt="R&D Logo" style={{ height: '85px', width: 'auto' }} />
          </figure>
        </a>
      </div>
      <div className="info-links" style={{ display: 'flex' }}>
        <div className="event-link" style={{ marginRight: '10px', flexFlow: 'column', display: 'flex', alignItems: 'center' }}>
          <a href="https://www.lincs-chamber.co.uk/members/rda-uk-ltd/" style={{ margin: '6px 0' }}>
            <img src={headerImgLCC} alt="LCC Event Image" style={{ height: '100px', width: 'auto' }} />
          </a>
          <a href="https://www.lincs-chamber.co.uk/members/rda-uk-ltd/" style={{ textDecoration: 'none', color: '#000', fontSize: '11px', wordWrap: 'break-word', width: '85px', lineHeight: '1.1em', textAlign: 'center' }}>
            Click here to find out more
          </a>
          </div>
        <div className="lcc-link" style={{ flexFlow: 'column', display: 'flex', alignItems: 'center' }}>
           <a href="https://www.the-mtc.org/events/industry-events/meet-make-manufacture/" style={{ margin: '6px 0' }}>
            <img src={headerImg} alt="Event Image" style={{ height: '100px', width: 'auto' }} />
          </a>
          <a href="https://www.the-mtc.org/events/industry-events/meet-make-manufacture/" style={{ textDecoration: 'none', color: '#000', fontSize: '11px', wordWrap: 'break-word', width: '85px', lineHeight: '1.1em', textAlign: 'center' }}>
            Click here to find out more
          </a>
        </div>
      </div>
    </TopNavInner>
  </MainGrid>
</TopNav>
  <MainGrid noPaddingMobile>
        <StickyNav>
          {stickyNavigation.map((edge, key) => {
            return (
              <StyledLink
                to={
                  (process.env.BASE === '/' ? '' : process.env.BASE) + edge.slug
                }
                key={key}
                firstLink={key === 0}
                activeLink={activeLink === edge.slug}
              >
                {edge.title}
              </StyledLink>
            )
          })}
        </StickyNav>
      </MainGrid>
    </Container>
  )
}

export default Navigation

const Container = styled.nav`
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
  display: none;
  @media screen and (min-width: 1024px) {
    padding: 0;
    display: flex;
  }
`
const TopNav = styled.div`
  border-bottom: 1px solid ${theme.grey.keyline};
`
const TopNavInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0 1.3rem;
`
interface LinkProp {
  activeLink?: boolean
}
const StyledLink = styled(props => <Link {...props} />)`
  &,
  &:link,
  &:visited {
    color: ${theme.text.main};
    font-family: Roboto;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    position: relative;
    text-decoration: none !important;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: ${props => (props.firstLink ? '0 2rem 0 0' : '0 2rem')};
    font-weight: 400;

    &::after {
      position: absolute;
      content: ' ';
      height: 3px;
      width: 80%;
      bottom: -1px;
      left: ${props => (props.firstLink ? '0' : '10%')};
      background: ${theme.brand.blue};
      transition: ease-out 0.3s;
      margin: 0 auto;
      transform: scaleX(0);
      transform: ${props => (props.activeLink ? 'scaleX(1)' : 'scaleX(0)')};
    }

    &:hover::after,
    &:active::after,
    &:focus::after {
      transform: scaleX(1);
    }
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
  }
`

const StickyNav = styled.div`
  display: none;

  @media screen and (min-width: 1024px) {
    display: flex;
  }
`
